import {useEffect, useState} from 'react';
import { Tab, Divider, Form } from 'semantic-ui-react';
import useUser from '../../Shared/UserProvider/useUser';
import { getMerchantsOfCompany } from '../../../services/Company/Company.service';
import OptionsForm from './OptionsForm';
import { useTranslation } from 'react-i18next';
import AuditPos from './AuditPos';
import { UltyDropdown } from '@ulty/ulty-ui';

const AdminHours = () => {
    const [t] = useTranslation();
    const { user } = useUser();
    const [pointOfSales, setPointOfSales] = useState([]);
    const [selectedPos, setSelectedPos] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        loadPointOfSales();
    }, []);

    const loadPointOfSales = async (selected) => {
        setIsLoading(true);
        const poss = await getMerchantsOfCompany(user.provider.type === 'COMPANY' ? user.provider.object.id : user.provider.object.company.id);
        setPointOfSales(poss);

        if (selected) {
            setSelectedPos(poss.filter(m => m.id === selected)[0]);
        } else {
            setSelectedPos(poss?.length > 0 ? poss[0] : null);
        }
        setIsLoading(false);
    }

    const handleMerchantChange = (e, { value }) => {
        setSelectedPos(pointOfSales.find(m => m.id === value));
    }

    return (
        <Tab.Pane>
            <Form>
                {user.provider.type === 'COMPANY' && (
                    <>
                        <Form.Field>
                            <label>{t('global.merchant')}</label>
                            <UltyDropdown
                                fluid
                                selection
                                loading={isLoading}
                                value={selectedPos?.id}
                                search
                                name="merchants"
                                onChange={handleMerchantChange}
                                options={pointOfSales.map(dm => ({
                                    text: dm.name,
                                    value: dm.id,
                                    key: dm.id
                                }))}
                            />
                        </Form.Field>
                        <Divider />
                    </>
                )}

                {selectedPos && selectedPos.id && (
                    <OptionsForm pointOfSale={selectedPos} reload={async () => {
                        await loadPointOfSales(selectedPos.id);
                    }} />
                )}
            </Form>

            { user.isStaff && selectedPos && (
                <AuditPos pos={selectedPos} />
            )}
        </Tab.Pane>
    )
}

export default AdminHours
