/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'

import React, { useCallback, useEffect } from 'react'

import { Tab } from "semantic-ui-react";
import {
  Route,
  NavLink,
  Switch,
  useHistory
} from "react-router-dom";
import moment from 'moment';

import {useTranslation} from 'react-i18next';
import useBreadcrumb from '../NavBar/useBreadcrumb';
import Revenues from './Revenues';
import Failures from './Failures';
import Detailed from './Detailed';
import useUser from '../Shared/UserProvider/useUser';
import Deployments from './Deployments';
import { useQuery } from '../../services/Filter/filter.service';
import PausedStores from './PausedStores';
import { isProviderAPos } from '../../services/Provider/Provider.service';
import {
    PerformanceReportConfigurationList
} from '../PerformanceReportConfiguration/PerformanceReportConfigurationList'

const defaultFilters = {
  dateRange: {
    startDate: moment().utc().add(-7, 'day').startOf('day'),
    endDate: moment(new Date()).utc().add(-1, 'day').endOf('day')
  },
  dateComparison: {
    startDate: moment().utc().add(-14, 'day').startOf('day'),
    endDate: moment(new Date()).utc().add(-8, 'day').endOf('day')
  },
    merchantId: null,
    brandIds: [],
    platformIds: []
};

const Home = () => {
  const [t] = useTranslation();
  const {setPaths} = useBreadcrumb();
  const {user} = useUser();

  const query = useQuery();
  const queryFilterFrom = query.get('from');
  const queryFilterTo = query.get('to');
  const queryFilterFromComparison = query.get('fromc');
  const queryFilterToComparison = query.get('toc');
  const queryFilterMerchant = query.get('merchant');
  const queryFilterBrands = query.get('brands');
  const queryFilterPlatforms = query.get('platforms');

  const history = useHistory();

  useEffect(() => {
    setPaths([{
      text: t('breadcrumb.home'),
      link: false,
      path: '/'
    }]);
  }, []);

  const replaceHistory = useCallback((filters) => {
    const filtersToQueryString = (filters) => {
      const strings = {
        merchant: filters.merchantId,
        brands: filters.brandIds,
        platforms: filters.platformIds,
        from: filters.dateRange?.startDate.toISOString(),
        to: filters.dateRange?.endDate.toISOString(),
        fromc: filters.dateComparison?.startDate ? filters.dateComparison.startDate.toISOString() : 'null',
        toc: filters.dateComparison?.endDate ? filters.dateComparison.endDate.toISOString() : 'null',
      };

      const queryStrings = Object.keys(strings).map(key => {
        let value = strings[key];

        if (!value) {
          return '';
        }

        if (Array.isArray(value)) {
          if (value.length > 0) {
            value = value.join();
          } else if (value.length === 0) {
            return ''
          }
        }

        return `&${key}=${value}`
      });

      const queryString = queryStrings.join('');
      return queryString.replace('&', '?');
    }

    history.replace({
      pathname: history?.location?.pathname||`/analytics`,
      search: filtersToQueryString({
        ...filters,
      })
    });
  }, [history]);

  const handleFiltersChange = (filters) => {
    replaceHistory(filters);
  }

  const panes = [
    {
      menuItem: {
        as: NavLink,
        id: "revenues",
        content: t('home.submenus.revenues'),
        to: `/analytics${history.location.search}`,
        exact: true,
        key: "revenues"
      },
      pane: (
        <Route
          path="/analytics"
          exact
          key="revenues"
          render={() => (
            <Revenues defaultFilters={{
              merchantId: queryFilterMerchant || (isProviderAPos(user.provider) ? user.provider.object.id : defaultFilters.merchantId),
              brandIds: queryFilterBrands ? queryFilterBrands.split(/,/) : defaultFilters.brandIds,
              platformIds: queryFilterPlatforms ? queryFilterPlatforms.split(/,/) : defaultFilters.platformIds,
              dateRange: {
                  startDate: queryFilterFrom || defaultFilters.dateRange.startDate,
                  endDate: queryFilterTo || defaultFilters.dateRange.endDate
              },
              dateComparison: {
                startDate: queryFilterFromComparison || defaultFilters.dateComparison.startDate,
                endDate: queryFilterToComparison || defaultFilters.dateComparison.endDate
              }
            }} handleFiltersChange={replaceHistory} />
          )}
        />
      )
    },
    {
      menuItem: {
        as: NavLink,
        id: "failures",
        content: t('home.submenus.failures'),
        to: `/analytics/missed${history.location.search}`,
        exact: true,
        key: "failures"
      },
      pane: (
        <Route
          path="/analytics/missed"
          exact
          key="failures"
          render={() => (
            <Failures defaultFilters={{
              merchantId: queryFilterMerchant || (isProviderAPos(user.provider) ? user.provider.object.id : defaultFilters.merchantId),
              brandIds: queryFilterBrands ? queryFilterBrands.split(/,/) : defaultFilters.brandIds,
              platformIds: queryFilterPlatforms ? queryFilterPlatforms.split(/,/) : defaultFilters.platformIds,
              dateRange: {
                  startDate: queryFilterFrom ? moment(queryFilterFrom).utc() : defaultFilters.dateRange.startDate,
                  endDate: queryFilterTo ? moment(queryFilterTo).utc() : defaultFilters.dateRange.endDate
              },
              dateComparison: {
                startDate: queryFilterFromComparison ? moment(queryFilterFromComparison).utc() : defaultFilters.dateComparison.startDate,
                endDate: queryFilterToComparison ? moment(queryFilterToComparison).utc() : defaultFilters.dateComparison.endDate
              }
            }} handleFiltersChange={handleFiltersChange} />
          )}
        />
      )
    },
    {
      menuItem: {
        as: NavLink,
        id: "detailed",
        content: t('home.submenus.detailed'),
        to: `/analytics/detailed${history.location.search}`,
        exact: true,
        key: "detailed"
      },
      pane: (
        <Route
          path="/analytics/detailed"
          exact
          key="detailed"
          render={() => (
            <Detailed defaultFilters={{
              merchantId: queryFilterMerchant || (isProviderAPos(user.provider) ? user.provider.object.id : defaultFilters.merchantId),
              brandIds: queryFilterBrands ? queryFilterBrands.split(/,/) : defaultFilters.brandIds,
              platformIds: queryFilterPlatforms ? queryFilterPlatforms.split(/,/) : defaultFilters.platformIds,
              dateRange: {
                  startDate: queryFilterFrom || defaultFilters.dateRange.startDate,
                  endDate: queryFilterTo || defaultFilters.dateRange.endDate
              },
              dateComparison: {
                startDate: queryFilterFromComparison || defaultFilters.dateComparison.startDate,
                endDate: queryFilterToComparison || defaultFilters.dateComparison.endDate
              }
            }} handleFiltersChange={replaceHistory} />
          )}
        />
      )
    },
    ...(user.provider.type === 'COMPANY' ? [{
      menuItem: {
        as: NavLink,
        id: "deployments",
        content: t('home.submenus.deployments'),
        to: `/analytics/deployments${history.location.search}`,
        exact: true,
        key: "deployments"
      },
      pane: (
          <Route
              path="/analytics/deployments"
              exact
              key="deployments"
              render={() => (
                  <Deployments defaultFilters={{
                    merchantId: queryFilterMerchant || (isProviderAPos(user.provider) ? user.provider.object.id : defaultFilters.merchantId),
                    brandIds: queryFilterBrands ? queryFilterBrands.split(/,/) : defaultFilters.brandIds,
                    platformIds: queryFilterPlatforms ? queryFilterPlatforms.split(/,/) : defaultFilters.platformIds,
                    dateRange: {
                        startDate: queryFilterFrom || defaultFilters.dateRange.startDate,
                        endDate: queryFilterTo || defaultFilters.dateRange.endDate
                    },
                    dateComparison: {
                      startDate: queryFilterFromComparison || defaultFilters.dateComparison.startDate,
                      endDate: queryFilterToComparison || defaultFilters.dateComparison.endDate
                    }
                  }} handleFiltersChange={replaceHistory} />
              )}
          />
      )
    }, {
      menuItem: {
        as: NavLink,
        id: "paused",
        content: t('home.submenus.paused'),
        to: `/analytics/paused${history.location.search}`,
        exact: true,
        key: "paused"
      },
      pane: (
          <Route
              path="/analytics/paused"
              exact
              key="paused"
              render={() => (
                  <PausedStores defaultFilters={{
                    merchantId: queryFilterMerchant || (isProviderAPos(user.provider) ? user.provider.object.id : defaultFilters.merchantId),
                    brandIds: queryFilterBrands ? queryFilterBrands.split(/,/) : defaultFilters.brandIds,
                    platformIds: queryFilterPlatforms ? queryFilterPlatforms.split(/,/) : defaultFilters.platformIds,
                    dateRange: {
                        startDate: queryFilterFrom || defaultFilters.dateRange.startDate,
                        endDate: queryFilterTo || defaultFilters.dateRange.endDate
                    },
                    dateComparison: {
                      startDate: queryFilterFromComparison || defaultFilters.dateComparison.startDate,
                      endDate: queryFilterToComparison || defaultFilters.dateComparison.endDate
                    }
                  }} handleFiltersChange={replaceHistory} />
              )}
          />
      )
    }] : []), {
          menuItem: {
              as: NavLink,
              id: 'performanceReportConfiguration',
              content: t('reports.receiveAReport'),
              to: `/analytics/reports`,
              exact: true,
              key: 'performanceReportConfiguration',
          },
          pane: (
            <Route
              path="/analytics/reports"
              exact
              render={() => <PerformanceReportConfigurationList/>}
            />
          ),
      }]

  return (
      <Switch>
        <Tab css={css`
        margin-top: 20px;
      `} renderActiveOnly={false} activeIndex={-1} panes={panes} menu={{ secondary: true, pointing: true, className: "wrapped" }} />
      </Switch>
  );
}

export default Home;
