/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';

import {useContext, useEffect} from 'react';
import { UltyModalWrapperContext } from '../Shared/UltyModalWrapper/UltyModalWrapperContext';
import ProviderChoice from './ProviderChoice';

import { Segment, Form, Input, Button, Container, Grid, Icon } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { useState, useRef } from 'react'

import {notify} from 'react-notify-toast';
import { login, loginToProvider } from '../../services/Auth/Auth.service';

import UserStore from '../Auth/store'
import { AUTH_TOKEN, MOBILE_THRESHOLD, RECAPTCHA_KEY } from '../../constants';

import ReCAPTCHA from 'react-google-recaptcha'
import useUser from '../Shared/UserProvider/useUser';
import { useTranslation } from 'react-i18next';
import useMediaQuery from 'beautiful-react-hooks/useMediaQuery';
import { useQueryClient } from '@tanstack/react-query'

const Login = ({ history }) => {
  const isMobile = useMediaQuery(`(max-width: ${MOBILE_THRESHOLD}px)`);
  const [t] = useTranslation();
  const store = UserStore.useContainer();
  const recaptcha = useRef();

  const {setUser, landingRoute} = useUser();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [captcha, setCaptcha] = useState('');
  const [loading, setLoading] = useState(false);
  const queryClient = useQueryClient()

  useEffect(() => {
    setUser({});
    localStorage.removeItem(AUTH_TOKEN);
  }, [setUser]);

  const { handleUltyModalWrapper } = useContext(UltyModalWrapperContext);

  const defineProviderChoiceModal = (providers, email) => {
    const modalSettings = {
      centered: false,
      title: t('login.choose_provider'),
      component: <ProviderChoice
          providers={providers}
          email={email}
          afterSubmit={async (data) => {
            await queryClient.clear()
            localStorage.setItem(AUTH_TOKEN, data.token);
            setUser(data.user);
            store.setLoading(true);

            history.replace(landingRoute(data.user.provider?.role.name));

            handleUltyModalWrapper(false, null);
          }}
      />
    };

    handleUltyModalWrapper(true, modalSettings);
  };

  const handleChange = (_, { name, value }) => {
    if (name === 'email') {
      setEmail(value)
    } else {
      setPassword(value)
    }
  }

  const handleSubmit = async () => {
    notify.hide()

    if (email === '' || password === '') {
      notify.show(t('login.missing_email_or_password'), 'error')
      return
    }

    setLoading(true)

    try {
      const data = await login(email.trim(), password, captcha);

      // Handle providers
      await queryClient.clear()
      localStorage.setItem(AUTH_TOKEN, data.token);
      if (data.user.providers && data.user.providers.length > 0) {
        if (data.user.providers.length === 1) {
          const dataProvider = await loginToProvider(data.user.providers[0].id, email);

          localStorage.setItem(AUTH_TOKEN, dataProvider.token);
          setUser(dataProvider.user);
          store.setLoading(true);

          history.replace(landingRoute(dataProvider.user.provider?.role.name));
        } else {
          defineProviderChoiceModal(data.user.providers, data.user.email);
        }
        return;
      }

      setUser(data.user);
      store.setLoading(true);

      history.replace(landingRoute(data.user.provider?.role.name));

      return;
    } catch(e) {
      if (e.message === "CAPTCHA") {
        setLoading(false);
        notify.show(t('login.invalid_captcha'), 'error');
      } else {
        setLoading(false);
        notify.show(t('login.invalid_email_or_password'), 'error');
      }
    }

    recaptcha.current.reset()
  }

  return (
    <Container css={css`
      margin-top: 6rem !important
    `}>
      <Grid textAlign="center">
        <Grid.Column computer={6} mobile={16} tablet={8} css={css`
          ${isMobile ? 'padding: 0 !important;' : ''}
        `}>
          <Segment raised textAlign="left">
            <Form>
              <Grid>
                <Grid.Column width={16} css={css` 
                  background-color: #009c95 !important;
                  padding: 1.5rem !important;
                  color: #ffffff !important;
                `}>
                  <div css={css` font-weight: bold; text-align: center; `}>{t('login.welcome')}</div>
                  <div css={css` text-align: center; `}>{t('login.connect_to_continue')}</div>
                </Grid.Column>
                <Grid.Column width={16} css={css` 
                  padding: 2rem !important;
                `}>
                  <Form.Field
                    control={Input}
                    name="email"
                    value={email}
                    onChange={handleChange}
                    label={t('login.email')}
                    placeholder={t('login.email')}
                  />
                  <Form.Field
                    control={Input}
                    type="password"
                    name="password"
                    onChange={handleChange}
                    label={t('login.password')}
                    placeholder={t('login.password')}
                  />
                </Grid.Column>

                <Grid.Column width={16} textAlign="center" css={css` 
                  padding: 2rem !important;
                  padding-top: 0 !important;

                  &>div>div>div {
                    margin: auto;
                  }
                `}>
                  <ReCAPTCHA
                    ref={recaptcha}
                    sitekey={RECAPTCHA_KEY}
                    onChange={(value) => setCaptcha(value)}
                  />
                </Grid.Column>
                <Grid.Column width={16} css={css` 
                  padding: 2rem !important;
                  padding-top: 0 !important;
                `}>
                  <Button fluid color="teal" type='submit' loading={loading} disabled={loading} onClick={handleSubmit}>{t('login.connect')}</Button>
                </Grid.Column>
                <Grid.Column width={16} textAlign="center" css={css` 
                  padding-top: 0 !important;
                  color: #74788d !important
                `}>
                  <Link to='/auth/forgot'><Icon name="lock" /> {t('login.forgotten_password')}</Link>
                </Grid.Column>
              </Grid>
            </Form>
          </Segment>
        </Grid.Column>
      </Grid>

    </Container>
  )
}

export default Login
